<template>
  <Modal :title="title" :active="modalActive" @modal-close="close" size="full" no-padding>
    <template v-slot:modal-body>
      <Loader :active="loading" />
      <MicroParcels v-if="microParcelsData.length > 0 && modalActive" :microParcelsData="microParcelsData"
        :isEditable="true" :displayBorders="true" :displayHeaders="false" @add-moda="searchModa" @delete-moda="deleteModa"
        @manage-grid="manageGrid" :displayBtn="plan.type.uid === 'MANUEL'" :startingPoint="plan?.point_depart?.uid" canPanY />
    </template>
    >
  </Modal>

  <Modal v-if="plan.type.uid === 'MANUEL'" title="Liste des modalités" :active="modalModaActive" :data="modalData"
    @modal-close="modalModaActive = false" no-scroll>
    <template v-slot:modal-title="{ data }">
      <template v-if="data">
        Ajouter une modalité à la parcelle {{ data[0].coordonnees }}
      </template>
    </template>
    <template v-slot:modal-body="{ data }">

      <div class="h5">Choix de la répétition</div>
      <div class="repetition-buttons">
        <Btn v-for="n in plan.nb_repetitions" :key="n" @click="addModa(null, n)" :text="`Rep ${n}`" color="default"
          :class="{ active: repToAdd === n }" size="sm" />
      </div>

      <div class="h5">Choix de la modalité</div>
      <div class="micro-parcel-list">
        <MicroParcel v-for="(item, index) in data" :key="index" :microParcelData="data[index]" :displayGeo="false"
          :displayComputedId="false" :displayRepetition="false" :displayModalityNumber="true" :displayDesignation="true"
          isInList :class="{ active: data[index] === modaToAdd }" @click="addModa(data[index], null)" />
      </div>
    </template>
  </Modal>

  <Modal v-if="plan.type.uid !== 'MANUEL'" title="Liste des modalités" :active="modalModaActive" :data="modalData"
    @modal-close="modalModaActive = false" no-scroll>
    <template v-slot:modal-title="{ data }">
      <template v-if="data && microParcelToAdd">
        Ajouter une modalité à la parcelle {{ microParcelToAdd.coordonnees }}
      </template>
    </template>
    <template v-slot:modal-body="{ data }">
      <template v-if="microParcelToAdd">
        <div class="h5">Modalités disponibles pour la répétition {{ microParcelToAdd?.repetition }}</div>
        <div class="micro-parcel-list">
          <template v-for="moda in data?.repetitions[microParcelToAdd?.repetition-1]" :key="moda">
            <MicroParcel :microParcelData="moda" :displayGeo="false" :displayComputedId="false" :displayRepetition="false"
              :displayModalityNumber="true" :displayDesignation="true" isInList
              :class="{ active: moda === modaToAdd }" @click="addModa(moda, microParcelToAdd?.repetition)" />
          </template>
        </div>
      </template>
      <template v-else>
        <div>Aucune modalite à placer</div>
      </template>

    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue'
import Loader from '@/components/layout/Loader.vue'
import MicroParcel from '@/components/micro-parcels/MicroParcel.vue'
import MicroParcels from '@/components/micro-parcels/MicroParcels.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'AddEditPlanModal',
  components: {
    Modal,
    Loader,
    MicroParcel,
    MicroParcels,
    Btn,
  },
  props: {
    modalActive: {
      type: Boolean,
    },
    essai: {
      type: Object,
    },
    plan: {
      type: Object,
    },
    editPlan: {
      type: Boolean,
    },
    compkey: {
      type: String,
    },
  },
  data() {
    return {
      title: null,
      loading: false,
      modalites: null,
      modalData: null,
      modalModaActive: false,
      microParcelsData: [],
      maxLine: 1,
      maxCol: 1,
      repetition: 1,
      modaToAdd: null,
      repToAdd: null,
      microParcelstoAdd: null,
      displayRep: [],
      microParcelToAdd: null,
    }
  },
  emits: ['modal-close'],
  watch: {
    editPlan() {
      this.title = "Création manuelle du plan d'essai"
      if (this.editPlan) {
        this.title = "Modifier le plan d'essai"
      }
    },
  },
  mounted() {
    this.loadModalData()
    this.title = "Modifier le plan d'essai"
  },

  methods: {
    searchModa(microParcelData) {
      this.microParcelToAdd = microParcelData
      if (this.plan.type.uid !== 'MANUEL') {
        this.displayRep = []
        const moda = this.$store.state.plan.plans.filter((item) => item.id === this.plan.id)
        this.modalData = moda.shift()
        this.modalModaActive = true
      } else {
        const data = []
        this.modalites.forEach((item) => {
          if (microParcelData) {
            const newmicroParcelData = this.helperService.cloneObject(
              microParcelData,
            )
            // eslint-disable-next-line no-param-reassign
            newmicroParcelData.modalite = item
            data.push(newmicroParcelData)
          }
        })

        this.modalData = this.sortByOrder(data)
        this.modalModaActive = true
      }
    },
    manageGrid(grid) {
      console.log('this.plan', this.plan.point_depart.uid)
      console.log('grid', grid)
      const paramObj = {}
      if (grid) {
        if (grid.x) {
          paramObj.x = grid.x
          if (this.plan.point_depart.uid === 'BG' || this.plan.point_depart.uid === 'BD') {
            paramObj.x = (grid.x === grid.mx) ? 1 : grid.mx
          }
        }
        if (grid.y) {
          paramObj.y = grid.y
          if (this.plan.point_depart.uid === 'HD' || this.plan.point_depart.uid === 'BD') {
            paramObj.y = (grid.y === grid.my) ? 1 : grid.my
          }
        }

        if (grid.type === 'add') {
          this.fetchService
            .patch(
              `essai/${this.essai.id}/plan/${this.plan.id}/microparcelle/mass_create`,
              paramObj,
            )
            .then(() => {
              this.loadModalData()
            })
        } else {
          this.fetchService
            .patch(
              `essai/${this.essai.id}/plan/${this.plan.id}/microparcelle/mass_delete`,
              paramObj,
            )
            .then(() => {
              this.loadModalData()
            })
        }
      }
    },
    addModa(modalite, repetition) {
      if (modalite !== null) {
        this.modaToAdd = modalite
      }
      if (repetition !== null) {
        this.repToAdd = repetition
      }

      if (this.repToAdd !== null && this.modaToAdd !== null) {
        this.postModa()
      }
    },
    postModa() {
      const obj = {
        type_uid: this.microParcelToAdd.type.uid,
        modalite_id: this.modaToAdd.modalite.id,
        repetition: this.repToAdd,
      }

      if (this.plan.type.uid !== 'MANUEL') {
        this.$store.dispatch('plan/removeModa', {
          plan_id: this.plan.id,
          modalite: this.modaToAdd.modalite,
          repetition: this.repToAdd,
        })
      }

      this.fetchService
        .put(
          `essai/${this.essai.id}/plan/${this.plan.id}/microparcelle/${this.microParcelToAdd.id}`,
          obj,
        )
        .then(() => {
          this.modalModaActive = false
          this.modaToAdd = null
          this.repToAdd = null
          this.loadModalData()
        })
    },
    deleteModa(microParcelData) {
      const obj = {
        type_uid: microParcelData.type.uid,
        modalite_id: null,
      }

      if (this.plan.type.uid !== 'MANUEL') {
        this.$store.dispatch('plan/addModa', {
          plan_id: this.plan.id,
          modalite: microParcelData.modalite,
          repetition: microParcelData.repetition,
        })
      }

      this.fetchService
        .put(
          `essai/${this.essai.id}/plan/${this.plan.id}/microparcelle/${microParcelData.id}`,
          obj,
        )
        .then(() => {
          this.loadModalData()
        })
    },
    loadModalData() {
      this.loading = true
      Promise.all([
        this.fetchService
          .get(`essai/${this.essai.id}/plan/${this.plan.id}/microparcelle`, {
            sort: 'x.ASC,y.ASC',
            limit: 0,
          })
          .then((response) => {
            this.microParcelsData = response.data

            if (this.plan.type.uid !== 'MANUEL') {
              this.$store.dispatch('plan/setPlan', this.plan)
            }
          }),
        this.fetchService
          .get(`protocole/${this.essai.protocole.id}/modalite`, {
            limit: 0,
          })
          .then((reponseModalite) => {
            this.modalites = reponseModalite.data
          }),
      ]).then(() => {
        this.loading = false
      })
    },
    close() {
      this.$emit('modal-close', this.activeModal)
    },
    sortByOrder(element) {
      return element.sort((a, b) => a.modalite.ordre - b.modalite.ordre)
    },
    canDisplayRep(rep) {
      const findRep = this.displayRep.indexOf(rep)
      if (findRep === -1) {
        this.displayRep.push(rep)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.micro-parcel-list {
  display: grid;
  gap: $gutter-half;

  @include bp("xs") {
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  }
}

.repetition-buttons {
  margin-bottom: $gutter;
  display: flex;
  flex-wrap: wrap;
  gap: $gutter-quarter;

  :deep(.btn) {
    @include h-padding($gutter-half);
    transition: all 0.125s;

    @include hocus() {
      background-color: $color-primary-lighty;
      color: white;
    }

    &.active {
      outline: $gutter-eighth solid $color-primary-lighty;
    }
  }
}
</style>
