<template>
  <Section>
    <Container>
      <h5>Résumé du protocole</h5>
      <h2>{{ essai?.protocole?.titre }}</h2>

      <div v-show="displayParams">
        <div class="protocol-counters">
          <div class="protocol-counter">
            <div class="protocol-counter-value">{{ facteur.length }}</div>
            <div class="protocol-counter-label">Facteurs</div>
          </div>
          <div class="protocol-counter">
            <div class="protocol-counter-value">{{ modalite.length }}</div>
            <div class="protocol-counter-label">
              <template v-if="facteur.length === 1"> Modalités </template>
              <template v-if="facteur.length > 1">
                Combinaisons de modalités
              </template>
            </div>
          </div>
        </div>

        <Radio id="planGenerationMode" label="Génération" required v-model="planGenerationMode" :items="[
          { label: 'Automatique', value: 'auto' },
          { label: 'Manuelle', value: 'manual' },
          {
            label: 'A partir d\'un plan existant de la bibliothèque',
            value: 'library',
          },
        ]" inputStyle="inline" />

        <div v-show="planGenerationMode === 'library'" v-if="modalite.length > 0">
          <SelectExtended v-if="helperService.userHasPermission('essaiplan_list')" id="plan_id" v-model="fromLibrary"
            label="Bibliothéque de plan" :apiParams="{
              sort: 'nom.ASC',
              limit: 0,
              filters: `nb_modalites:eq(${modalite.length})`
            }" optionKey="id" optionValue="nom" :apiEndpoint="`essai/plan`" />
        </div>
        <div v-show="planGenerationMode !== 'library'">
          <Input id="generateFromLibrary" type="hidden" />
          <div class="grid margin-bottom">
            <div class="col-tmp" v-show="planGenerationMode === 'auto' && typeFilter">
              <SelectExtended id="type" required label="Type de dispositif" :apiParams="typeFilter" optionKey="uid"
                optionValue="designation" :apiEndpoint="`dictionnaire/type/dispositif`" :search="false" />
            </div>

            <div class="col-tmp" v-show="planGenerationMode === 'manual' && typeFilter">
              <SelectExtended id="typeAnalyse" required label="Type de dispositif pour l'analyse statistique "
                :apiParams="typeFilter" optionKey="uid" optionValue="designation"
                :apiEndpoint="`dictionnaire/type/dispositif`" :search="false" />
            </div>

          </div>
          <Radio v-show="currentFormValues?.type?.key === 'SPLITPLOT' ||
            currentFormValues?.type?.key === 'CRISSCROSS'
            " required id="facteurPrincipal" label="Facteur principal" :items="displayFacteurs" inputStyle="inline" />

          <div class="grid margin-bottom" v-show="currentFormValues?.type?.key !== 'CARRE'">
            <div class="col-tmp">
              <Input id="nb_repetitions"
                type="number"
                label="Nombre de répétitions"
                :options="{min: currentFormValues?.type?.key === 'BLOCS' ? 2 : 1 }"
                required />
            </div>
            <div class="col-tmp">
              <Radio id="orientation_generale" v-show="currentFormValues?.type?.key !== 'ALPHAPLAN'"
                label="Orientation générale" :items="generalOrientation" inputStyle="inline" />
            </div>
          </div>
          <div class="grid margin-bottom" v-show="currentFormValues?.type?.key === 'BLOCS' ||
            currentFormValues?.type?.key === 'SPLITPLOT' ||
            currentFormValues?.type?.key === 'CRISSCROSS'
            ">
            <div class="col-tmp">
              <Checkbox id="showAdvancedParams" label="Parametres Avancés" v-model="showAdvancedParams" :items="[
                {
                  label: 'Afficher les paramètres avancés',
                  value: 'true',
                },
              ]" />

              <SelectExtended v-show="showAdvancedParams" id="blocksize" required label="Dimension des blocs (LxC)"
                optionKey="value" optionValue="label" :items="possibleBlockSize" />

              <Radio v-show="showAdvancedParams" id="est_variation_temoin" label="Traitement des témoins"
                text-info="Prendre en compte les témoins comme une variation de modalité." inputStyle="inline" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                ]" />
            </div>
          </div>

          <div class="grid grid--compact margin-bottom">
            <div class="col-tmp">
              <Radio class="input-block--radio-square" id="point_depart" label="Point de départ"
                :items="startingPoints" />
            </div>
            <div class="col-tmp">
              <Checkbox v-if="borders.length > 0" id="bordures" v-model="bordersVmodel" :items="borders"
                label="Bordures" />
            </div>
            <div class="col-tmp">
              <Input id="numero_depart" type="number" label="Numéro de départ microparcelle" text-info="coordonnées géographique de départ"  />
            </div>
            <div class="col-tmp" v-show="planGenerationMode === 'auto' &&
              currentFormValues?.type?.key === 'BLOCS'
              ">
              <SelectExtended id="randomisation" label="Randomisation interne des blocs"
                :apiParams="{ sort: 'designation.ASC' }" optionKey="uid" optionValue="designation"
                :apiEndpoint="`dictionnaire/randomisation`" />
            </div>
          </div>

          <div class="grid margin-bottom">
            <div class="col-tmp" v-if="planGenerationMode === 'auto'">
              <SelectExtended id="temoin" label="Ajout de zone témoin" :apiParams="{ sort: 'designation.ASC' }"
                optionKey="uid" optionValue="designation" :apiEndpoint="`dictionnaire/implantation_temoins`"
                @update:modelValue="changeTemoin" />
            </div>
            <div class="col-tmp" v-if="planGenerationMode === 'auto' &&
              currentFormValues?.temoin?.key === 'IMBRIQUES'
              ">
              <SelectExtended v-show="planGenerationMode === 'auto'" id="position_temoins" label="Position des témoins"
                required :apiParams="{
                  sort: 'designation.ASC',
                }" optionKey="uid" optionValue="designation" :apiEndpoint="`dictionnaire/marge`" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  </Section>

  <div class="view-footer" v-show="displayParams">
    <div class="view-actions view-actions--left">
      <Btn text="Annuler" :to="{ name: 'essais' }" />

      <Btn v-if="$route.name.includes('Add')" :to="{ name: previousRouteName, params: routeParams }" text="Précédent"
        icon="chevron-big-left" />

      <Btn hollow text="Retour au plan" v-if="plan" @click="displayParams = false" />

      <Btn v-if="helperService.userHasPermission(
        'essaiplan_oedit,essaiplan_oadd',
        'OR'
      )
        " text="Enregistrer les modifications et pré-visualiser" color="primary" icon="chevron-big-right" icon-after
        @click="onSubmit()" />
    </div>
  </div>

  <Section class="no-padding-top" v-show="!displayParams">
    <template v-if="microParcelsData.length > 0">
      <Container>
        <div class="button-wrapper">
          <Btn v-if="!hasNotation &&
            helperService.userHasPermission(
              'essaiplan_oedit,essaiplan_oadd',
              'OR'
            )
            " text="Modifier les paramètres du plan" @click="displayParams = true" hollow />
          <Btn v-if="!hasNotation && helperService.userHasPermission('essaiplan_oedit')
            " text="Modification manuelle" @click="editModal = true" hollow />
          <Btn v-if="helperService.userHasPermission('essaiplan_add')"
            text="Enregistrer le plan dans la bibliothèque de plans" @click="saveModal = true" hollow />
          <Btn text="Imprimer le plan" hollow icon="print" @click="printPlan('pdf')" />
          <Btn text="Télécharger le plan (xls)" hollow icon="print" @click="printPlan('xls')" />
        </div>

        <div class="grid margin-bottom">
          <div class="tmp-col">
            <DisplayPlanOptions v-if="plan" :planId="plan.id" @display-plan-options="updateDisplayOptions" />
          </div>
          <div class="tmp-col">
            <DisplayPlanModalitiesOptions v-if="essai && plan" :planId="plan.id" :essai="essai"
              @display-plan-modalities-options='updateDisplayModalitiesOptions' />
          </div>
        </div>
      </Container>
      <MicroParcels v-if="microParcelsData.length > 0" :microParcelsData="microParcelsData" :isEditable="false"
        :borders="bordersVmodel" :displayBorders="true" :displayHeaders="displayOptions?.options?.displayHeaders"
        :displayGeo="displayOptions?.options?.displayGeo" :displayComputedId="displayOptions?.options?.displayComputedId"
        :displayRepetition="displayOptions?.options?.displayRepetition"
        :displayModalityNumber="displayOptions?.options?.displayModalityNumber"
        :displayDesignation="displayOptions?.options?.displayDesignation"
        :displayColors="displayOptions?.options?.displayColors" :hiddenModalities="displayOptions?.modalites_off"
        :startingPoint="plan?.point_depart?.uid" canPanY />
    </template>
    <template v-else>
      <Container>
        Le plan n'a pas encore été généré<br />
        <Btn v-if="helperService.userHasPermission('essaiplan_oadd')" text="Retour au paramètres du plan"
          @click="displayParams = true" hollow />
      </Container>
    </template>
  </Section>

  <div class="view-footer" v-show="!displayParams">
    <div class="view-actions view-actions--left">
      <Btn text="Annuler" :to="{ name: 'essais' }" />

      <Btn v-if="$route.name.includes('Add')" :to="{ name: previousRouteName, params: routeParams }" text="Précédent"
        icon="chevron-big-left" />

      <Btn v-if="$route.name.includes('Add')" color="primary" text="Enregistrer " :to="{ name: 'essais' }" />
    </div>
  </div>

  <Modal v-if="plan" title="Enregistrer le plan dans la bibliothèque" :active="saveModal" :data="plan" :containForm="true"
    :modalValidationSchema="modalValidationSchema" @modal-close="saveModal = false"
    @modal-form-submitted="savePlanInLibrary($event)">
    <template v-slot:modal-body>
      <Input id="nom" label="Désignation du plan" />
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="saveModal = false" />
      <Btn btnType="submit" text="Enregistrer" color="primary" />
    </template>
  </Modal>

  <AddEditPlanModal v-if="plan && editModal" :modalActive="editModal" :essai="essai" :plan="plan" :editPlan="editPlan"
    @modal-close="closeAddEdit()" />
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Radio from '@/components/form/Radio.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Input from '@/components/form/Input.vue'
import Btn from '@/components/base/Btn.vue'
import MicroParcels from '@/components/micro-parcels/MicroParcels.vue'
import AddEditPlanModal from '@/views/componentsViews/plan/AddEditPlanModal.vue'
import DisplayPlanOptions from '@/views/componentsViews/plan/DisplayPlanOptions.vue'
import DisplayPlanModalitiesOptions from '@/views/componentsViews/plan/DisplayPlanModalitiesOptions.vue'
import Modal from '@/components/layout/Modal.vue'
import Checkbox from '@/components/form/Checkbox.vue'

export default {
  name: 'TestPlanAddEditSubview',
  components: {
    DisplayPlanModalitiesOptions,
    DisplayPlanOptions,
    AddEditPlanModal,
    SelectExtended,
    MicroParcels,
    Container,
    Checkbox,
    Section,
    Radio,
    Modal,
    Input,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      parametresavances: this.yup.boolean().nullable(),
      planGenerationMode: this.yup.object().nullable(),
      generateFromLibrary: this.yup.string().nullable(),
      est_variation_temoin: this.yup.boolean().nullable(),
      type: this.yup.object().nullable(),
      typeAnalyse: this.yup.object().nullable(),
      facteurPrincipal: this.yup
        .number()
        .when('[type]', (type, schema) => (type?.key === 'SPLITPLOT' || type?.key === 'CRISSCROSS'
          ? schema.required()
          : schema.nullable())),
      orientation_general: this.yup.object().nullable(),
      nb_repetitions: this.yup.number().min(1).nullable(),
      blocksize: this.yup
        .object()
        .when(
          ['type', 'parametresavances'],
          (type, parametresavances, schema) => (type?.key === 'BLOCS' && parametresavances === true
            ? schema.required()
            : schema.nullable()),
        ),
      point_depart: this.yup.object().nullable(),
      temoin: this.yup.object().nullable(),
      position_temoins: this.yup
        .object()
        .when('[temoin]', (temoin, schema) => (temoin?.key === 'IMBRIQUES' ? schema.required() : schema.nullable())),
      randomisation: this.yup.object().nullable(),
      numero_depart: this.yup.number().nullable(),
    })

    // Define a validation schema
    const modalValidationSchema = this.yup.object().shape({
      nom: this.yup.string().required(),
    })

    // init du form
    const {
      errors, isSubmitting, currentFormValues, setFieldValue,
    } = this.formService.initFrom(validationSchema)

    // gestion du onSubmit
    const onSubmit = this.formService.handleSubmit((values) => {
      this.savePlan(values)
    })

    return {
      onSubmit,
      setFieldValue,
      errors,
      isSubmitting,
      currentFormValues,
      modalValidationSchema,
      displayParams: false,
      essai: {},
      plan: null,
      facteur: {},
      modalite: {},
      startingPoints: [],
      generalOrientation: [],
      borders: [],
      bordersVmodel: [],
      planGenerationMode: null,
      fromLibrary: null,
      editPlan: true,
      microParcelsData: [],
      displayFacteurs: [],
      displayOptions: {},
      possibleBlockSize: [],
      editModal: false,
      saveModal: false,
      typeFilter: null,
      showAdvancedParams: false,
      hasNotation: false,
      previousRouteName: null,
      routeParams: null,
    }
  },
  emits: ['set-step', 'set-data', 'set-route', 'update:modelValue'],
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
    modelValue() {
      this.$emit('update:modelValue', false)
    },
    planGenerationMode(newValue, oldValue) {
      if (newValue === 'auto' && oldValue === 'manual' && this.currentFormValues.type.key === 'MANUEL') {
        this.formService.setFieldValue('type', {
          key: 'RANDOM',
          value: 'Randomisation totale',
        })
      }
    },
    showAdvancedParams() {
      if (this.showAdvancedParams) {
        this.setFieldValue('parametresavances', true)
      } else {
        this.setFieldValue('parametresavances', false)
      }
    },
  },
  mounted() {
    if (localStorage.getItem('referentials')) {
      this.referentials = JSON.parse(localStorage.getItem('referentials'))
    }

    // set params for parent
    this.previousRouteName = 'essaiParcelleAdd'
    this.routeParams = { id: this.$route.params.id }

    this.$emit('set-step', 2)

    this.planGenerationMode = 'auto'
    this.endPoint = 'essai'
    if (this.$route.params.id) {
      this.method = 'put'
      this.endPoint += `/${this.$route.params.id}`
      this.loadData()
      this.getNotation()
    }
  },
  methods: {

    async savePlan(values) {
      this.emitter.emit('open-loader')
      if (this.fromLibrary !== null) {
        await this.fetchService.get(`essai/plan/${this.fromLibrary.key}`).then(
          (reponse) => {
            this.plan = reponse.data
            this.fromLibrary = null
            this.populateForm(this.plan.id)
            this.onSubmit()
          },
        )
      } else {
        let typeDispositif = values.type.key
        if (this.planGenerationMode === 'manual') {
          typeDispositif = values?.typeAnalyse?.key
          if (!typeDispositif || typeDispositif === null) {
            this.formService.setFieldError('typeAnalyse', 'Le champ est obligatoire')
            this.emitter.emit('close-loader')
            return
          }
        } else if (!typeDispositif || typeDispositif === null) {
          this.formService.setFieldError('type', 'Le champ est obligatoire')
          this.emitter.emit('close-loader')
          return
        }

        if (typeDispositif !== 'CARRE' && values.nb_repetitions === null) {
          this.formService.setFieldError(
            'nb_repetitions',
            'Le champ est obligatoire',
          )
          this.emitter.emit('close-loader')
          return
        }

        if (typeDispositif === 'BLOCK' && values.nb_repetitions === 1) {
          this.formService.setFieldError(
            'nb_repetitions',
            'Le nombre de répétition doit être supérieur à 1',
          )
          this.emitter.emit('close-loader')
          return
        }

        // controle alfaplan
        if (typeDispositif === 'ALPHAPLAN') {
          if (
            this.modalite.length < 10
            || this.modalite.length > 60
            || values.nb_repetitions < 3
            || values.nb_repetitions > 6
          ) {
            this.emitter.emit('alert', {
              type: 'error',
              content: `Impossible de générer un Alpha Plan.<br/>
              Le nombre de modalités présente dans l’essai doit être compris entre 10 et 60<br/>
              Le nombre de répétition doit être compris entre 3 et 6`,
            })
            this.emitter.emit('close-loader')
            return
          }
        }

        const formValues = this.helperService.cloneObject(values)

        formValues.randomisation_uid = values.randomisation?.key
        formValues.implantation_temoins_uid = values.temoin?.key
        formValues.point_depart_uid = values.point_depart
        formValues.facteur_id = values.facteurPrincipal
        formValues.marge_uid = values.position_temoins?.key
        formValues.orientation_generale_uid = values.orientation_generale
        formValues.est_variation_temoin = values.est_variation_temoin
        formValues.parametresavances = values.parametresavances === true
        formValues.bordures = this.bordersVmodel.map((bordure) => ({
          uid: bordure,
        }))

        const departX = parseInt(values.numero_depart.slice(0, -2), 10)
        const departY = parseInt(values.numero_depart.slice(-2), 10)

        if (values.orientation_generale === 'HORIZONTALE') {
          formValues.depart_x = departX
          formValues.depart_y = departY
        } else {
          formValues.depart_x = departY
          formValues.depart_y = departX
        }

        // poour le dispositif blocs on inverse la direction et on inverse
        // la disposition des blocs
        let defaultblockSize = {}
        if (values?.type?.key === 'BLOCS') {
          if (values.orientation_generale === 'HORIZONTALE') {
            // eslint-disable-next-line prefer-destructuring
            defaultblockSize = this.possibleBlockSize[0]
          } else {
            defaultblockSize = this.possibleBlockSize[this.possibleBlockSize.length - 1]
          }

          let blocksizeValues = defaultblockSize?.value?.split('x')
          if (values.parametresavances === true) {
            blocksizeValues = values.blocksize.key.split('x')
          }
          const [line, column] = blocksizeValues
          formValues.nb_lignes = line
          formValues.nb_colonnes = column
        }

        if (this.planGenerationMode === 'manual') {
          formValues.type_uid = 'MANUEL'
          formValues.type_analyse_uid = values.typeAnalyse.key
        } else {
          formValues.type_uid = values.type.key
        }

        if (values.type.key === 'CARRE') {
          formValues.nb_repetitions = this.modalite.length
        }

        this.fetchService
          .post(`essai/${this.$route.params.id}/plan`, formValues)
          .then((responsePlan) => {
            this.plan = responsePlan.data

            let params = null
            if (formValues.generateFromLibrary) {
              params = { plan: formValues.generateFromLibrary }
            }

            this.fetchService
              .patch(
                `essai/${this.$route.params.id}/plan/${this.plan.id}/generer`,
                params,
              )
              .then(
                () => {
                  this.emitter.emit('close-loader')
                  this.loadData(true)
                },
                (responseError) => {
                  this.formService.handleApiError(
                    responseError,
                    responseError?.message,
                  )
                  this.emitter.emit('close-loader')
                },
              )
          })
      }
    },

    savePlanInLibrary(data) {
      this.emitter.emit('open-loader')
      const formValues = this.helperService.cloneObject(this.plan)

      if (formValues.type.uid === 'MANUEL') {
        formValues.type_uid = 'MANUEL'
        formValues.type_analyse_uid = formValues.type_analyse.uid
      } else {
        formValues.type_uid = formValues.type.uid
      }

      formValues.nom = data.nom
      formValues.orientation_generale_uid = formValues.orientation_general?.uid
      formValues.point_depart_uid = formValues.point_depart?.uid
      formValues.marge_uid = formValues.position_temoins?.uid
      formValues.nb_modalites = this.modalite.length
      formValues.disposition = formValues.id
      formValues.bordures = formValues.bordures.map((bordure) => ({
        uid: bordure.uid,
      }))
      delete formValues.id

      this.fetchService
        .post('essai/plan', formValues)
        .then(() => {
          this.emitter.emit('close-loader')
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le plan a bien été enregistré dans la bibliothèque.',
          })
        })
        .finally(() => {
          this.saveModal = false
          this.emitter.emit('close-loader')
        })
    },
    loadData(toEditModal = false) {
      this.emitter.emit('open-loader')
      this.fetchService
        .get(`essai/${this.$route.params.id}`)
        .then((response) => {
          this.essai = response.data
          this.plan = response.data.plan
          this.$emit('set-data', this.essai)
          Promise.all([
            this.fetchService
              .get(`protocole/${this.essai.protocole.id}/facteur`)
              .then((reponseFacteur) => {
                this.facteur = reponseFacteur.data

                if (this.facteur.length === 2) {
                  this.typeFilter = {
                    sort: 'designation.ASC',
                    filters: 'uid:in(CARRE,RANDOM,BLOCS,SPLITPLOT,CRISSCROSS)',
                  }
                } else {
                  this.typeFilter = {
                    sort: 'designation.ASC',
                    filters: 'uid:in(CARRE,RANDOM,BLOCS)',
                  }
                }

                if (this.facteur.length === 1) {
                  this.typeFilter = {
                    sort: 'designation.ASC',
                    filters: 'uid:in(CARRE,RANDOM,BLOCS,ALPHAPLAN)',
                  }
                }

                if (this.facteur.length >= 4) {
                  this.typeFilter = {
                    sort: 'designation.ASC',
                    filters: 'uid:in(RANDOM)',
                  }
                }

                this.displayFacteurs = []
                this.facteur.forEach((fact) => {
                  const fatorName = fact.designation !== null
                    ? fact.designation
                    : fact.type.designation
                  this.displayFacteurs.push({
                    label: `Facteur: ${fatorName}`,
                    value: fact.id,
                  })
                })
              }),
            this.fetchService
              .get(`protocole/${this.essai.protocole.id}/modalite`, {
                limit: 0,
              })
              .then((reponseModalite) => {
                this.modalite = reponseModalite.data
              }),
            this.fetchService
              .get('dictionnaire/point_depart', { sort: 'id.ASC' })
              .then((reponseDepart) => {
                this.startingPoints = []
                reponseDepart.data.forEach((point) => this.startingPoints.push({
                  label: point.designation,
                  value: point.uid,
                }))
              }),
            this.fetchService
              .get('dictionnaire/orientation_generale', {
                sort: 'designation.ASC',
                filters: '',
              })
              .then((reponseDepart) => {
                this.generalOrientation = []
                reponseDepart.data.forEach((point) => this.generalOrientation.push({
                  label: point.designation,
                  value: point.uid,
                }))
              }),
            this.fetchService
              .get('dictionnaire/bordure')
              .then((reponseDepart) => {
                this.borders = []
                reponseDepart.data.forEach((point) => this.borders.push({
                  label: point.designation,
                  value: point.uid,
                }))
              }),
          ]).then(() => {
            if (this.essai.plan) {
              this.calcBlockSizePossible()
              this.populateForm()
              this.loadMicroParcels()

              if (toEditModal && this.planGenerationMode === 'manual') {
                this.editPlan = false
                this.editModal = true
              }
            } else {
              this.displayParams = true
              this.calcBlockSizePossible()
              this.populateForm()
            }
            this.emitter.emit('close-loader')
          })
        })
    },
    populateForm(planId = null) {
      // Populate schema with data
      let schema = {}
      if (this.plan) {
        schema = this.formService.populateShema(this.plan)
      }

      if (schema) {
        schema.generateFromLibrary = planId
      }

      this.planGenerationMode = 'auto'
      if (this.plan?.type) {
        this.typeDispositif = this.plan?.type?.uid

        schema.type = {
          key: this.plan?.type?.uid,
          value: this.plan?.type?.designation,
        }
        if (this.plan?.type?.uid === 'MANUEL') {
          this.planGenerationMode = 'manual'

          schema.typeAnalyse = {
            key: this.plan?.type_analyse?.uid,
            value: this.plan?.type_analyse?.designation,
          }
        }
        if (this.plan?.type?.uid === 'MANUEL') {
          this.planGenerationMode = 'manual'
          schema.type = {
            key: this.plan?.type?.uid,
            value: this.plan?.type?.designation,
          }
        }
        if (this.plan?.type?.uid === 'MANUEL') {
          this.planGenerationMode = 'manual'
          schema.type = {
            key: this.plan?.type?.uid,
            value: this.plan?.type?.designation,
          }
        }
      } else {
        schema.type = {
          key: 'RANDOM',
          value: 'Randomisation totale',
        }
      }

      // schema.facteurPrincipal = this.plan.facteur.uid
      if (this.plan?.facteur) {
        schema.facteurPrincipal = this.plan.facteur.id
      }

      if (this.plan?.implantation_temoins) {
        schema.temoin = {
          key: this.plan?.implantation_temoins?.uid,
          value: this.plan?.implantation_temoins?.designation,
        }
      }
      if (this.plan?.randomisation) {
        schema.randomisation = {
          key: this.plan?.randomisation?.uid,
          value: this.plan?.randomisation?.designation,
        }
      }

      if (this.plan?.point_depart) {
        schema.point_depart = this.plan?.point_depart?.uid
      } else {
        try {
          const ptdep = JSON.parse(this.$store.getters['app/parameters']([
            'ESSAI_POINTDEPART',
          ]).at(0).valeur)
          schema.point_depart = typeof (ptdep) === 'object' && ptdep ? ptdep.key : 'HG'
        } catch (e) {
          console.log(e)
        }
      }

      if (this.plan?.bordures !== null && this.plan?.bordures !== undefined) {
        this.bordersVmodel = this.plan.bordures.map((bordure) => bordure.uid)
      }

      if (
        this.plan?.orientation_generale !== null
        && this.plan?.orientation_generale !== undefined
      ) {
        schema.orientation_generale = this.plan.orientation_generale.uid
      } else {
        try {
          const orientation = JSON.parse(this.$store.getters['app/parameters']([
            'ESSAI_ORIENTATION',
          ]).at(0).valeur)
          schema.orientation_generale = typeof (orientation) === 'object' && orientation
            ? orientation.key
            : 'HORIZONTALE'
        } catch (e) {
          console.log(e)
        }
      }

      if (
        this.plan?.parametresavances !== undefined
        && this.plan?.parametresavances === true
      ) {
        schema.parametresavances = true
        this.showAdvancedParams = true
      } else {
        schema.parametresavances = false
        this.showAdvancedParams = false
      }

      schema.est_variation_temoin = false
      if (this.plan?.est_variation_temoin) {
        schema.est_variation_temoin = this.plan?.est_variation_temoin
      }

      if (
        this.plan?.nb_lignes !== null
        && this.plan?.nb_colonnes !== null
        && this.plan?.nb_lignes !== undefined
        && this.plan?.nb_colonnes !== undefined
      ) {
        schema.blocksize = {
          key: `${this.plan.nb_lignes}x${this.plan.nb_colonnes}`,
          value: `${this.plan.nb_lignes}x${this.plan.nb_colonnes}`,
        }
      }

      if (!this.plan?.nb_repetitions) {
        schema.nb_repetitions = this.plan?.type?.uid === 'BLOCK' ? 2 : 1
      }

      // eslint-disable-next-line
      Number.prototype.pad = function (n) {
        return new Array(n).join('0').slice((n || 2) * -1) + this
      }

      if (!this.plan?.depart_x) {
        schema.numero_depart = '101'
      } else if (schema.orientation_generale === 'HORIZONTALE') {
        schema.numero_depart = `${this.plan?.depart_x}${this.plan?.depart_y.pad(2)}`
      } else {
        schema.numero_depart = `${this.plan?.depart_y}${this.plan?.depart_x.pad(2)}`
      }

      this.formService.setFieldValue('blocksize', schema.blocksize)

      // set form values
      this.formService.setFormValues(schema)
    },
    loadMicroParcels() {
      this.fetchService
        .get(`essai/${this.essai.id}/plan/${this.plan.id}/microparcelle`, {
          sort: 'x.ASC,y.ASC',
          limit: 0,
        })
        .then((responseMicroparcelle) => {
          this.microParcelsData = responseMicroparcelle.data
          if (responseMicroparcelle.data.length > 0) {
            this.displayParams = false
          }
        })
    },
    updateDisplayOptions(options) {
      this.displayOptions.options = options
    },
    updateDisplayModalitiesOptions(options) {
      if (options && options.length) {
        this.displayOptions.modalites_off = options.join(',')
      } else {
        this.displayOptions.modalites_off = ''
      }
    },
    calcBlockSizePossible() {
      this.possibleBlockSize = []
      const divisorNumber = []
      let modalites = this.modalite

      if (this.currentFormValues?.temoin?.key === 'EXCLUS') {
        modalites = modalites.filter((moda) => moda.type.uid === 'PROTOCOLE')
      }

      for (let i = 1; i <= modalites.length; i += 1) {
        const modulo = modalites.length % i
        if (modulo === 0) {
          divisorNumber.push(i)
        }
      }
      const divisorNumberReverse = [...divisorNumber].reverse()

      for (let i = 0; i < divisorNumber.length; i += 1) {
        this.possibleBlockSize.push({
          value: `${divisorNumber[i]}x${divisorNumberReverse[i]}`,
          label: `${divisorNumber[i]}x${divisorNumberReverse[i]}`,
        })
      }
    },
    closeAddEdit() {
      this.editModal = false
      this.editPlan = true
      this.loadData()
    },
    getNotation() {
      this.fetchService
        .get(`essai/${this.$route.params.id}/evenement`, {
          limit: 0,
          filters: 'type.uid:eq(NOTATION)',
        })
        .then((responsEvenement) => {
          responsEvenement.data.forEach((item) => {
            this.fetchService
              .get(
                `essai/${this.$route.params.id}/evenement/${item.id}/fiche/notation`,
                { limit: 0 },
              )
              .then((responseNotation) => {
                responseNotation.data.forEach((notation) => {
                  this.fetchService
                    .get(
                      `essai/${this.$route.params.id}/evenement/${item.id}/fiche/notation/${notation.id}/reservation`,
                      { limit: 0 },
                    )
                    .then((responseReservation) => {
                      if (responseReservation.data.length > 0) {
                        this.hasNotation = true
                      }
                    })
                })
              })
          })
        })
    },
    printPlan(type) {
      const printOptions = {
        position_geographique: this.displayOptions?.options.displayGeo ? 1 : 0,
        modalite_bloc: this.displayOptions?.options?.displayComputedId ? 1 : 0,
        numero_ligne: this.displayOptions?.options?.displayHeaders ? 1 : 0,
        numero_colonne: this.displayOptions?.options?.displayHeaders ? 1 : 0,
        numero_modalite: this.displayOptions?.options?.displayModalityNumber ? 1 : 0,
        numero_bloc: this.displayOptions?.options?.displayRepetition ? 1 : 0,
        libelle_modalite: this.displayOptions?.options?.displayDesignation ? 1 : 0,
        couleurs: this.displayOptions?.options?.displayColors ? 1 : 0,
      }

      if ('modalites_off' in this.displayOptions) {
        printOptions.modalites_off = this.displayOptions.modalites_off
      }

      this.fileService.exportFile(
        type,
        'essai',
        this.essai.id,
        'plan',
        printOptions,
      )
    },
    changeTemoin() {
      this.calcBlockSizePossible()
      const result = this.possibleBlockSize.find(
        (item) => item.value === this.currentFormValues?.blocksize?.value,
      )
      if (result === undefined) {
        const [first] = this.possibleBlockSize
        this.formService.setFieldValue('blocksize', first)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// RADIO SQUARE

.input-block--radio-square {
  :deep(.input--block) {
    position: relative;
    @include size(5rem);

    &::before {
      content: "";
      display: block;
      @include size(3rem);
      border: 1px solid $color-gray;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    .radio {
      position: absolute;

      &:nth-child(1) {
        top: -1rem;
        left: 0;
      }

      &:nth-child(2) {
        top: -1rem;
        right: 0;
      }

      &:nth-child(3) {
        bottom: -1rem;
        left: 0;
      }

      &:nth-child(4) {
        bottom: -1rem;
        right: 0;
      }

      label {
        >span {
          display: none;
        }

        &::before {
          background-color: white;
        }
      }
    }
  }
}
</style>
